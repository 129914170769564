<template>
  <div>
    <button
      v-if="islistaTablaVacia === false"
      class="btn text-center"
      :class="classButton"
      v-b-modal.modal-formaPagos
    >
     Forma de Pago
    </button>
    <b-modal
      id="modal-formaPagos"
      size="lg"
      title="Forma de Pago"
      hide-footer
    >
      <template #modal-header>
        <div class="row col-md-12 px-0">
          <div class="col-md-6 pt-2">
            <h5>Forma de Pago</h5>
          </div>
          <div class="col-md-6 text-right">
            <button class="btn btn-sm btn-warning col-sm-3"
            @click="agregarFormaPago">Agregar</button>
            <button type="button" class="close btn-modal-pagos"
            @click="$bvModal.hide('modal-formaPagos')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </template>
      <template v-if="!getCheckFacturaTercero">
        <div class="row mb-2">
          <div class="col-sm-7">
            <div class="row py-1 mb-3">
              <div class="col-sm-3 py-2">
                <b-form-radio
                  v-model="radioFormaPagoselected"
                  :disabled="isDisabledFormaPagoCredito"
                  name="radio-tipo"
                  :value="getTipoFormaPagos[1].id"
                >
                  {{getTipoFormaPagos[1].name}}
                </b-form-radio>
              </div>
              <div v-if="radioFormaPagoselected===getTipoFormaPagos[1].id" class="row col-sm-8">
                <div class="col-sm-6 pl-0">
                  <b-input-group append="días">
                    <b-form-input v-model="inputDiasCredito" type="number" min="0"
                    @input="sumarFechaCreditoVencimiento">
                    </b-form-input>
                  </b-input-group>
                </div>
                <div class="col-sm-6 px-0">
                  <label class="col-form-label" v-show="inputDiasCredito!=''">
                    Vence: {{fechaCreditoVencimiento}}
                  </label>
                </div>
              </div>
            </div>
            <div class="row py-1">
              <div class="col-sm-3 pt-2">
                <b-form-radio v-model="radioFormaPagoselected"
                 @change="agregarMedioPagoPorDefecto"
                 :disabled="isDisabledFormaPago
                  || getTipoFactura === 5"
                 name="radio-tipo"
                 :value="getTipoFormaPagos[0].id">
                  {{getTipoFormaPagos[0].name}}
                </b-form-radio>
              </div>
            </div>
          </div>
          <div class="col-sm-5">
            <div class="row pl-4 mb-3">
              <input
                id="modalCheck1"
                v-model="checkCXP"
                class="form-check-input"
                type="checkbox"
                @click="onChangeCheckCXP"
                :disabled="isBloqueadoAjusteAnticipo
                  || getTipoFactura === 5"
              >
              <label class="form-check-label" for="modalCheck1">
                Ajuste: viene de CXP
              </label>
            </div>
            <div v-show="checkCXP" class="row">
              <div class="col-sm-6 pl-0">
                <multiselect v-model="modalComboAjustesSelected"
                  :options="ajustesPersonasFiltered"
                  @select="onSelectComboAjustes"
                  placeholder="Seleccione"
                  :allow-empty="false"
                  track-by="id"
                  :show-labels="false"
                  select-label=""
                  deselect-label="X">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span style="font-size:13px;">
                      {{ option.origen_type }}: {{ option.numero }}
                    </span>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <small>
                      {{ option.origen_type }}:
                      {{ option.numero }}
                      {{ option.descripcion === null? '': '- '+ option.descripcion }}
                    </small>
                  </template>
                  <span slot="caret" v-if="!(modalComboAjustesSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                  <span slot="noOptions">Lista Vacia</span>
                </multiselect>
              </div>
              <div class="col-sm-3 px-0">
                <vue-numeric class="form-control"
                  @keyup.enter.native="addAjuste()"
                  v-model="montoAjustes"
                  separator=","
                  :max="maxMontoAjustes"
                  v-bind:precision="2"
                />
              </div>
              <div class="col-sm-3">
                <button class="btn btn-success btn-sm"
                 @click="addAjuste()">+</button>
              </div>
            </div>
            <div class="row mt-2" v-show="checkCXP">
              <div class="col-sm-12">
                 <table class="table table-hover">
                  <thead>
                  <tr>
                    <th scope="col" class="p-2">N°</th>
                    <th scope="col" class="p-2">Monto</th>
                    <th scope="col" class="p-2"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(ajuste, index) in ajustesEnUso" :key="index">
                    <td>{{ ajuste.origen_type }}:{{ ajuste.numero }}</td>
                    <td>{{ ajuste.monto | numeral('0,0.00') }}</td>
                    <td>
                      <i class="nav-icon i-Close-Window font-weight-bold text-danger"
                        style="cursor: pointer"
                        @click="deleteAjuste(index)"
                      ></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-12">
            <div v-if="radioFormaPagoselected===getTipoFormaPagos[0].id" class="col-sm-8">
              <!-- seccion combo tesorerias  -->
              <div class="row mb-1">
                <div class="col-sm-1 pt-2">
                  <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                    v-if="nroCajas >= 2"
                    style="cursor: pointer" @click="actualizarOpciones1"></i>
                </div>
                <div class="col-sm-6 pl-2">
                  <multiselect
                    v-model="modalComboSelected"
                    :options="comboModalPago"
                    @input="agregarMedioPagoPorDefecto"
                    placeholder="Seleccione"
                    label="nombre" track-by="id"
                    :show-labels="false"
                    select-label=""
                    deselect-label="X">
                    <template slot="singleLabel" slot-scope="{ option }">
                      <span style="font-size:13px;">
                        {{ option.nombre }}
                      </span>
                    </template>
                    <span slot="caret" v-if="!(modalComboSelected===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                  </multiselect>
                </div>
                <div class="col-sm-3 px-0 pt-1">
                  <vue-numeric class="form-control"
                    v-model="monto1"
                    @change.native="actualizarMonto2"
                    separator=","
                    v-bind:precision="2"
                    :min="0"
                    :max="getTotal - totalMontoAjustes"
                    :disabled="nroCajas==1"
                  />
                </div>
                <div class="col-sm-1 pt-1" v-if="nroCajas==1">
                  <button class="btn btn-success btn-sm px-3"
                    @click="agregarCuenta">+</button>
                </div>
              </div>
              <div class="row mb-1" v-if="nroCajas>1">
                <div class="col-sm-1 pt-2">
                  <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                    v-if="nroCajas >= 2"
                    style="cursor: pointer" @click="actualizarOpciones2"></i>
                </div>
                <div class="col-sm-6 pl-2">
                  <multiselect v-model="modalComboSelected2"
                    :options="comboModalPago"
                    placeholder="Seleccione"
                    label="nombre" track-by="id"
                    :show-labels="false"
                    select-label=""
                    deselect-label="X">
                    <template slot="singleLabel" slot-scope="{ option }">
                      <span style="font-size:13px;">
                        {{ option.nombre }}
                      </span>
                    </template>
                    <span slot="caret" v-if="!(modalComboSelected2===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                  </multiselect>
                </div>
                <div class="col-sm-3 px-0 pt-1">
                  <vue-numeric class="form-control"
                    v-model="monto2"
                    @change.native="actualizarMonto1"
                    separator=","
                    v-bind:precision="2"
                    :min="0"
                  />
                </div>
                <div class="col-sm-1 pt-1" v-if="nroCajas==2">
                  <button class="btn btn-success btn-sm px-3"
                    @click="agregarCuenta">+</button>
                </div>
              </div>
              <div class="row mb-1" v-if="nroCajas===3">
                <div class="col-sm-1 pt-2">
                  <i class="nav-icon i-Close-Window text-danger font-weight-bold"
                    v-if="nroCajas === 3"
                    style="cursor: pointer" @click="actualizarOpciones3"></i>
                </div>
                <div class="col-sm-6 pl-2">
                  <multiselect v-model="modalComboSelected3"
                    :options="comboModalPago"
                    placeholder="Seleccione"
                    label="nombre" track-by="id"
                    :show-labels="false"
                    select-label=""
                    deselect-label="X">
                    <template slot="singleLabel" slot-scope="{ option }">
                      <span style="font-size:13px;">
                        {{ option.nombre }}
                      </span>
                    </template>
                    <span slot="caret" v-if="!(modalComboSelected3===null)"></span>
                    <span slot="noResult">Sin Resultados</span>
                  </multiselect>
                </div>
                <div class="col-sm-3 px-0 pt-1">
                  <vue-numeric class="form-control"
                    v-model="monto3"
                    @change.native="actualizarMonto1"
                    separator=","
                    v-bind:precision="2"
                    :min="0"
                  />
                </div>
              </div>
              <!-- fin -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <div class="row" v-if="radioFormaPagoselected===getTipoFormaPagos[0].id">
              <div class="col-sm-3 text-right">
                <label class="col-form-label">Medio de Pago:</label>
              </div>
              <div class="col-sm-4 px-0">
                <multiselect v-model="modalComboMedioPagoSelected"
                  :options="getComboModalMedioPago"
                  @input="observarMedioPago"
                  placeholder="Seleccione"
                  label="nombre" track-by="id"
                  :show-labels="false"
                  select-label=""
                  deselect-label="X">
                  <template slot="singleLabel" slot-scope="{ option }">
                    <span style="font-size:13px;">
                      {{ option.nombre }}
                    </span>
                  </template>
                  <span slot="caret" v-if="!(modalComboMedioPagoSelected===null)"></span>
                  <span slot="noResult">Sin Resultados</span>
                </multiselect>
              </div>
              <!-- Renderizado por MedioPago Seleccionado -->
              <div v-if="esMedioPagoBanco || esMedioPagoDeposito || esMedioPagoTarjeta"
               class="col-sm-5">
                <div class="row pt-1">
                  <div class="col-sm-6 pr-0">
                    <input type="text"
                      v-model="comprobante"
                      class="form-control"
                      :maxlength="modalComboMedioPagoSelected.id === 4 ? '4' : ''"
                      :placeholder="modalComboMedioPagoSelected.id === 4 ? 'Primero 4': modalComboMedioPagoSelected.comprobante"
                      @keydown="validarSoloNumeros"
                    >
                  </div>
                  <div class="col-sm-5 pr-0">
                    <input
                      v-if="modalComboMedioPagoSelected.id === 4"
                      v-model="numeroTarjeta"
                      type="text"
                      class="form-control"
                      placeholder="Ultimo 4"
                      maxlength="4"
                      @keydown="validarSoloNumeros"
                    >
                  </div>
                </div>
                <div class="text-small text-danger" v-if="modalComboMedioPagoSelected.id === 4">
                  Digito de la Tarjeta
                </div>
              </div>
              <div v-if="esMedioPagoCheque" class="col-sm-5 pt-1">
                <div class="row">
                  <div class="col-sm-7 pl-2 pr-0">
                    <date-picker
                      v-model="comprobante"
                      class="col-sm-12 px-0"
                      lang="es"
                      format="DD/MM/YYYY"
                      type="date"
                      value-type="date">
                    </date-picker>
                  </div>
                  <div class="col-sm-5 pl-1 pr-0">
                      <input type="text"
                      v-model="nroTransaccion"
                      class="form-control"
                      placeholder="N°">
                  </div>
                </div>
              </div>
              <div v-if="esMedioPagoPaypal || esMedioPagoLibra || esMedioPagoBitcoin"
               class="col-sm-5 pt-1">
                <input type="text"
                  v-model="comprobante"
                  class="form-control col-sm-8"
                  :placeholder="modalComboMedioPagoSelected.comprobante">
              </div>
            </div>
          </div>
          <div class="row col-sm-4">
            <label class="col-form-label col-sm-5 text-right">Importe:</label>
            <input type="text"
             class="form-control col-sm-6"
             :value="getTotal | numeral('0,0.00')"
             autocomplete="off"
             disabled>
          </div>
        </div>
      </template>
      <template v-else>
        <FormaPagoFacturaTercero />
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VueNumeric from 'vue-numeric';
import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} from 'vuex';
import { mapFields } from 'vuex-map-fields';
import FormaPagoFacturaTercero from './FormaPagoFacturaTercero.vue';
import { util } from '../../../plugins/util';

export default {
  name: 'FormaPagoModal',
  components: {
    Multiselect,
    FormaPagoFacturaTercero,
    DatePicker,
    VueNumeric,
  },
  created() {
    this.getFormaPagoApi();
  },
  data() {
    return {
      modalComboAjustesSelected: null,
      montoAjustes: 0,
    };
  },
  methods: {
    observarMedioPago() {
      this.numeroTarjeta = null;
      if (this.esMedioPagoCheque) {
        this.comprobante = this.getDatePicker;
      } else {
        this.comprobante = null;
      }
    },
    formatearDate(fecha, separador, modo = 'any') {
      const year = fecha.getFullYear();
      const month = fecha.getMonth() + 1;
      const day = fecha.getDate();
      if (modo === 'ymd') {
        return `${year}${separador}${month}${separador}${day}`;
      }
      return `${day}${separador}${month}${separador}${year}`;
    },
    formatearFecha(fechaYMD) {
      const fechaTemp = fechaYMD.split('-');
      const year = fechaTemp[0];
      const month = fechaTemp[1];
      const day = fechaTemp[2];
      return `${day}/${month}/${year}`;
    },
    actualizarFechaModalPagos() {
      const tempFecha = this.formatearDate(this.getDatePicker, '-', 'ymd');
      this.fechaCreditoVencimiento = this.formatearFecha(tempFecha);
    },
    sumarFechaCreditoVencimiento() {
      if (this.inputDiasCredito === '') {
        this.actualizarFechaModalPagos();
        return;
      }
      const diasCredito = this.inputDiasCredito;
      const tempDate = this.formatearDate(this.getDatePicker, '-', 'ymd');
      const fechaTemp = this.formatearFecha(tempDate).split('/');
      const day = parseInt(fechaTemp[0], 10) + parseInt(diasCredito, 10);
      const month = fechaTemp[1] - 1;
      const year = fechaTemp[2];
      const FechaNueva = new Date(year, month, day);
      this.fechaCreditoVencimiento = this.formatearDate(FechaNueva, '/');
    },
    disminuirCuenta() {
      this.nroCajas -= 1;
    },
    actualizarOpciones1() {
      this.disminuirCuenta();
      this.modalComboSelected = null;
      this.monto1 = this.getTotal - this.totalMontoAjustes;
      this.modalComboSelected2 = null;
      this.monto2 = 0;
      this.monto3 = 0;
    },
    actualizarOpciones2() {
      this.disminuirCuenta();
      this.modalComboSelected2 = null;
      this.modalComboSelected3 = null;
      this.monto1 = this.getTotal - this.totalMontoAjustes;
      this.monto2 = 0;
      this.monto3 = 0;
    },
    actualizarOpciones3() {
      this.disminuirCuenta();
      this.modalComboSelected3 = null;
      this.monto1 = this.getTotal - this.totalMontoAjustes;
      this.monto2 = 0;
      this.monto3 = 0;
    },
    agregarCuenta() {
      if (this.nroCajas < 3) {
        this.nroCajas += 1;
      }
    },
    actualizarMonto1() {
      this.monto1 = this.getTotal - this.totalMontoAjustes - this.monto2 - this.monto3;
    },
    actualizarMonto2() {
      this.monto2 = this.getTotal - this.totalMontoAjustes - this.monto1;
    },
    PagofacturaTercero() {
      if (this.caja > this.getTotal) {
        util.showNotify('Caja debe ser menor que el total', 'warn');
        return;
      }
      if (this.banco > this.getTotal) {
        util.showNotify('Banco debe ser menor que el total', 'warn');
        return;
      }
      if (this.tarjeta > this.getTotal) {
        util.showNotify('Tarjeta debe ser menor que el total', 'warn');
        return;
      }
      if (this.caja + this.banco + this.tarjeta > this.getTotal) {
        util.showNotify('la sumatoria es mayor que el total', 'warn');
        return;
      }
      this.classButton = 'btn-success';
      if (this.caja > 0) {
        const BADGE_CAJA = { nombre: `Caja: ${parseFloat(this.caja).toFixed(2)}` };
        this.addItemListaBadges(BADGE_CAJA);
      }
      if (this.banco > 0) {
        const BADGE_BANCO = { nombre: `Banco: ${parseFloat(this.banco).toFixed(2)}` };
        this.addItemListaBadges(BADGE_BANCO);
      }
      if (this.tarjeta > 0) {
        const BADGE_TARJETA = { nombre: `Tarjeta: ${parseFloat(this.tarjeta).toFixed(2)}` };
        this.addItemListaBadges(BADGE_TARJETA);
      }
      this.$bvModal.hide('modal-formaPagos');
    },
    PagoNormal() {
      if (this.islistaTablaVacia) {
        util.showNotify('Agrege al menos un Servicio en la factura', 'warn');
        return;
      }
      if (this.radioFormaPagoselected === null) {
        util.showNotify('Seleccione una Forma de Pago', 'warn');
        return;
      }
      if (this.nroCajas === 2 && this.monto2 === 0) {
        util.showNotify('El segundo monto debe ser mayor a 0', 'warn');
        return;
      }
      if (this.nroCajas === 3 && this.monto3 === 0) {
        util.showNotify('El tercer monto debe ser mayor a 0', 'warn');
        return;
      }
      const CREDITO = this.getTipoFormaPagos[1].id;
      const CONTADO = this.getTipoFormaPagos[0].id;
      const BADGE_FORMA_PAGO = { nombre: this.getNameFormaPagoSelected };
      this.addItemListaBadges(BADGE_FORMA_PAGO);
      if (this.radioFormaPagoselected === CREDITO) {
        if (this.inputDiasCredito === '') {
          util.showNotify('Indique los Días de Credito', 'warn');
          return;
        }
        const BADGE_DIAS_CREDITO = { nombre: `${this.inputDiasCredito} Días` };
        this.addItemListaBadges(BADGE_DIAS_CREDITO);
        const BADGE_FECHA_VENC = { nombre: `Venc.: ${this.fechaCreditoVencimiento}` };
        this.addItemListaBadges(BADGE_FECHA_VENC);
      }
      if (this.radioFormaPagoselected === CONTADO) {
        if (this.modalComboMedioPagoSelected === null) {
          util.showNotify('Elija un Medio de Pago', 'warn');
          return;
        }

        if (this.modalComboSelected === null) {
          util.showNotify('Elija una Tesoreria', 'warn');
          return;
        }
        const BADGE_TESORERIA_1 = { nombre: this.modalComboSelected.nombre };
        this.addItemListaBadges(BADGE_TESORERIA_1);

        if (this.nroCajas >= 2) {
          if (this.modalComboSelected2 === null) {
            util.showNotify('Elija otra Tesoreria', 'warn');
            return;
          }
          const BADGE_TESORERIA_2 = { nombre: this.modalComboSelected2.nombre };
          this.addItemListaBadges(BADGE_TESORERIA_2);
        }
        if (this.nroCajas === 3) {
          if (this.modalComboSelected3 === null) {
            util.showNotify('Elija otra Tesoreria', 'warn');
            return;
          }
          const BADGE_TESORERIA_3 = { nombre: this.modalComboSelected3.nombre };
          this.addItemListaBadges(BADGE_TESORERIA_3);
        }

        if (this.modalComboMedioPagoSelected !== null) {
          const BADGE_MEDIO_PAGO = { nombre: this.modalComboMedioPagoSelected.nombre };
          this.addItemListaBadges(BADGE_MEDIO_PAGO);
        }
      }
      this.classButton = 'btn-success';
      this.$bvModal.hide('modal-formaPagos');
    },
    PagoConAjustes() {
      if (this.islistaTablaVacia) {
        util.showNotify('Agrege al menos un Servicio en la factura', 'warn');
        return;
      }
      if (this.totalMontoAjustes === this.getTotal && this.radioFormaPagoselected === null) {
        this.ajustesEnUso.forEach((item) => {
          const monto = this.$options.filters.numeral(item.monto, '0,0.00');
          const nombre = `Ajuste: ${item.origen_type} ${item.numero} ${monto}`;
          const BADGE_CXP = { nombre };
          this.addItemListaBadges(BADGE_CXP);
        });
        this.classButton = 'btn-success';
        this.$bvModal.hide('modal-formaPagos');
      } else {
        if (this.radioFormaPagoselected === null) {
          util.showNotify('Seleccione una Forma de Pago para completar el monto.', 'warn');
          return;
        }
        if (this.nroCajas === 2 && this.monto2 === 0) {
          util.showNotify('El segundo monto debe ser mayor a 0', 'warn');
          return;
        }
        if (this.nroCajas === 3 && this.monto3 === 0) {
          util.showNotify('El tercer monto debe ser mayor a 0', 'warn');
          return;
        }
        const CREDITO = this.getTipoFormaPagos[1].id;
        const CONTADO = this.getTipoFormaPagos[0].id;
        const BADGE_FORMA_PAGO = { nombre: this.getNameFormaPagoSelected };
        this.addItemListaBadges(BADGE_FORMA_PAGO);
        if (this.radioFormaPagoselected === CREDITO) {
          if (this.inputDiasCredito === '') {
            util.showNotify('Indique los Días de Credito', 'warn');
            return;
          }
          const BADGE_DIAS_CREDITO = { nombre: `${this.inputDiasCredito} Días` };
          this.addItemListaBadges(BADGE_DIAS_CREDITO);
          const BADGE_FECHA_VENC = { nombre: `Venc.: ${this.fechaCreditoVencimiento}` };
          this.addItemListaBadges(BADGE_FECHA_VENC);
        }
        if (this.radioFormaPagoselected === CONTADO) {
          if (this.modalComboMedioPagoSelected === null) {
            util.showNotify('Elija un Medio de Pago', 'warn');
            return;
          }

          if (this.modalComboSelected === null) {
            util.showNotify('Elija una Tesoreria', 'warn');
            return;
          }
          const BADGE_TESORERIA_1 = { nombre: this.modalComboSelected.nombre };
          this.addItemListaBadges(BADGE_TESORERIA_1);

          if (this.nroCajas >= 2) {
            if (this.modalComboSelected2 === null) {
              util.showNotify('Elija otra Tesoreria', 'warn');
              return;
            }
            const BADGE_TESORERIA_2 = { nombre: this.modalComboSelected2.nombre };
            this.addItemListaBadges(BADGE_TESORERIA_2);
          }
          if (this.nroCajas === 3) {
            if (this.modalComboSelected3 === null) {
              util.showNotify('Elija otra Tesoreria', 'warn');
              return;
            }
            const BADGE_TESORERIA_3 = { nombre: this.modalComboSelected3.nombre };
            this.addItemListaBadges(BADGE_TESORERIA_3);
          }

          if (this.modalComboMedioPagoSelected !== null) {
            const BADGE_MEDIO_PAGO = { nombre: this.modalComboMedioPagoSelected.nombre };
            this.addItemListaBadges(BADGE_MEDIO_PAGO);
          }
        }
        this.ajustesEnUso.forEach((item) => {
          const monto = this.$options.filters.numeral(item.monto, '0,0.00');
          const nombre = `Ajuste: ${item.origen_type} ${item.numero} ${monto}`;
          const BADGE_CXP = { nombre };
          this.addItemListaBadges(BADGE_CXP);
        });
        this.classButton = 'btn-success';
        this.$bvModal.hide('modal-formaPagos');
      }
    },
    agregarFormaPago() {
      this.classButton = 'btn-danger';
      this.setListaBadges([]);
      if (this.getCheckFacturaTercero) {
        this.PagofacturaTercero();
      } else if (this.checkCXP) {
        this.PagoConAjustes();
      } else {
        this.PagoNormal();
      }
    },
    agregarMedioPagoPorDefecto() {
      const ID_CAJA = 1;
      const ID_BANCO = 2;
      const ID_TARJETA = 3;
      const ID_EFECTIVO = 1;
      const ID_TRANSFERENCIA = 2;
      const ID_TAJETA_CREDITO = 4;
      this.comprobante = null;
      if (this.modalComboSelected === null) {
        return;
      }
      if (this.modalComboSelected.tipo_id === ID_CAJA) {
        const MEDIO_PAGO_DEFAULT = this.getComboModalMedioPago.find((el) => el.id === ID_EFECTIVO);
        this.modalComboMedioPagoSelected = MEDIO_PAGO_DEFAULT;
        return;
      }
      if (this.modalComboSelected.tipo_id === ID_BANCO) {
        const MEDIO_PAGO_DEFAULT = this.getComboModalMedioPago.find((el) => (
          el.id === ID_TRANSFERENCIA
        ));
        this.modalComboMedioPagoSelected = MEDIO_PAGO_DEFAULT;
      }
      if (this.modalComboSelected.tipo_id === ID_TARJETA) {
        const MEDIO_PAGO_DEFAULT = this.getComboModalMedioPago.find((el) => (
          el.id === ID_TAJETA_CREDITO
        ));
        this.modalComboMedioPagoSelected = MEDIO_PAGO_DEFAULT;
      }
    },
    onChangeCheckCXP() {
      this.modalComboAjustesSelected = null;
      this.montoAjustes = 0;
      this.ajustesEnUso = [];
      this.monto1 = this.getTotal - this.totalMontoAjustes;
      if (this.checkCXP === false) {
        this.getAjustesPersonaApi();
      }
    },
    onSelectComboAjustes(data) {
      this.montoAjustes = parseFloat(data.importe_actual);
    },
    addAjuste() {
      if (this.modalComboAjustesSelected === null) {
        util.showNotify('Debe seleccionar una Cuenta por Cobrar!', 'warn');
        return;
      }
      if (this.montoAjustes <= 0) {
        util.showNotify('Debe agregar un monto mayor a cero!', 'warn');
        return;
      }
      if (this.montoAjustes + this.totalMontoAjustes > this.getTotal) {
        util.showNotify('El monto mas lo agregado es mayor al importe total!', 'warn');
        return;
      }
      const ITEM = {
        id: this.modalComboAjustesSelected.id,
        origen_type: this.modalComboAjustesSelected.origen_type,
        numero: this.modalComboAjustesSelected.numero,
        monto: this.montoAjustes,
      };
      this.ajustesEnUso.push(ITEM);
      if (this.totalMontoAjustes === this.getTotal) {
        this.radioFormaPagoselected = null;
      }
      this.modalComboAjustesSelected = null;
      this.montoAjustes = 0;
      this.monto1 = this.getTotal - this.totalMontoAjustes;
    },
    deleteAjuste(indice) {
      this.ajustesEnUso = this.ajustesEnUso.filter((el, index) => index !== indice);
      this.monto1 = this.getTotal - this.totalMontoAjustes;
    },
    ...mapMutations('detalleFactura', [
      'setListaBadges',
      'addItemListaBadges',
    ]),
    ...mapActions('formaPagoModal', [
      'getFormaPagoApi',
      'getAjustesPersonaApi',
    ]),
    ...mapMutations('formaPagoModal', [
      'totalMontoAjusteIguales',
    ]),
    validarSoloNumeros(event) {
      if (this.modalComboMedioPagoSelected && this.modalComboMedioPagoSelected.id === 4) {
        const validKeys = new RegExp('^[0-9,]*$');
        const controlKeys = ['Delete', 'Backspace', 'ArrowLeft', 'ArrowRight', 'KeyX', 'KeyC', 'KeyV', 'Home', 'End', 'Tab'];
        if (controlKeys.includes(event.code)) {
          switch (event.code) {
            case 'KeyX':
              if (!event.ctrlKey) {
                event.preventDefault();
              }
              break;
            case 'KeyC':
              if (!event.ctrlKey) {
                event.preventDefault();
              }
              break;
            case 'KeyV':
              if (!event.ctrlKey) {
                event.preventDefault();
              }
              break;
            default:
              break;
          }
          return;
        }
        if (!validKeys.test(event.key)) {
          event.preventDefault();
        }
      }
    },
  },
  computed: {
    ...mapFields('formaPagoModal', [
      'radioFormaPagoselected',
      'nroCajas',
      'inputDiasCredito',
      'fechaCreditoVencimiento',
      'modalComboMedioPagoSelected',
      'comprobante',
      'nroTransaccion',
      'checkCXP',
      'ajustesPersonas',
      'ajustesEnUso',
      'modalComboSelected',
      'modalComboSelected2',
      'modalComboSelected3',
      'monto1',
      'monto2',
      'monto3',
      'classButton',
      'numeroTarjeta',
    ]),
    ...mapState('formaPagoFacturaTercero', [
      'caja',
      'banco',
      'tarjeta',
    ]),
    isDisabledFormaPago() {
      this.totalMontoAjusteIguales(this.totalMontoAjustes === this.getTotal);
      return (
        this.islistaTablaVacia
        || this.getModoEdicion
        || this.totalMontoAjustes === this.getTotal
      );
    },
    isDisabledFormaPagoCredito() {
      if (this.isBloqueadoDarACredito) {
        return true;
      }
      return this.isDisabledFormaPago;
    },
    comboModalPago() {
      if (this.modalComboSelected === null) {
        return this.getComboModalPago;
      }
      const COMBO_TEMPORAL2 = this.getComboModalPago.filter((el) => (
        el.id !== this.modalComboSelected.id
      ));
      if (this.modalComboSelected2 === null) {
        return COMBO_TEMPORAL2;
      }
      const COMBO3 = COMBO_TEMPORAL2.filter((el) => (
        el.id !== this.modalComboSelected2.id
      ));
      if (this.modalComboSelected3 === null) {
        return COMBO3;
      }
      return COMBO3.filter((el) => (
        el.id !== this.modalComboSelected3.id
      ));
    },
    esMedioPagoBanco() {
      if (this.modalComboMedioPagoSelected === null) {
        return false;
      }
      if (this.modalComboMedioPagoSelected.id === 2) {
        return true;
      }
      return false;
    },
    esMedioPagoDeposito() {
      if (this.modalComboMedioPagoSelected === null) {
        return false;
      }
      if (this.modalComboMedioPagoSelected.id === 3) {
        return true;
      }
      return false;
    },
    esMedioPagoTarjeta() {
      if (this.modalComboMedioPagoSelected === null) {
        return false;
      }
      if (this.modalComboMedioPagoSelected.id === 4) {
        return true;
      }
      return false;
    },
    esMedioPagoCheque() {
      if (this.modalComboMedioPagoSelected === null) {
        return false;
      }
      if (this.modalComboMedioPagoSelected.id === 5) {
        return true;
      }
      return false;
    },
    esMedioPagoPaypal() {
      if (this.modalComboMedioPagoSelected === null) {
        return false;
      }
      if (this.modalComboMedioPagoSelected.id === 6) {
        return true;
      }
      return false;
    },
    esMedioPagoLibra() {
      if (this.modalComboMedioPagoSelected === null) {
        return false;
      }
      if (this.modalComboMedioPagoSelected.id === 7) {
        return true;
      }
      return false;
    },
    esMedioPagoBitcoin() {
      if (this.modalComboMedioPagoSelected === null) {
        return false;
      }
      if (this.modalComboMedioPagoSelected.id === 8) {
        return true;
      }
      return false;
    },
    ajustesPersonasFiltered() {
      if (this.ajustesEnUso.length === 0) {
        return this.ajustesPersonas;
      }
      let arrayTemporal = [...this.ajustesPersonas];
      this.ajustesEnUso.forEach((aj) => {
        arrayTemporal = arrayTemporal.filter((el) => (
          el.id !== aj.id
        ));
      });
      return arrayTemporal;
    },
    totalMontoAjustes() {
      let limite = 0.00;
      this.ajustesEnUso.forEach((item) => {
        limite = parseFloat(limite) + parseFloat(item.monto);
      });
      return limite;
    },
    maxMontoAjustes() {
      if (this.modalComboAjustesSelected === null) {
        return 0;
      }
      return parseFloat(this.modalComboAjustesSelected.importe_actual);
    },
    ...mapGetters('main', [
      'isBloqueadoAjusteAnticipo',
      'isBloqueadoDarACredito',
    ]),
    ...mapGetters('facturacion', [
      'getModoEdicion',
    ]),
    ...mapGetters('formFactura', [
      'getCheckFacturaTercero',
      'getDatePicker',
      'getCheckSeguroEstado',
      'getTipoFactura',
    ]),
    ...mapGetters('formaPagoModal', [
      'getComboModalMedioPago',
      'getComboModalPago',
      'getTipoFormaPagos',
      'getNameFormaPagoSelected',
    ]),
    ...mapGetters('tableAndAddService', [
      'islistaTablaVacia',
    ]),
    ...mapGetters('detalleFactura', [
      'getTotal',
    ]),
  },
};
</script>

<style scoped>
.btn-modal-pagos{
  transform: translate(30px, 0px);
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
